import React from 'react';
import { Helmet } from 'react-helmet';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import Title from '../../components/Title/Title';
import PreFooter from '../../components/PreFooter/PreFooter';
import Cta from '../../components/Cta/Cta';
import folderGlass from '../../images/folder-glass.svg';
import ContactForm from '../../components/ContactForm';
import './contactUs.scss';

const breadcrumb = [
    { anchor: '/', name: 'Home' },
    { anchor: '/contacts/', name: 'Contact Us' },
];

const ContactUs = () => {
  return (
    <PageWrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact the Search Guard team - get in touch with us</title>
        <link rel="canonical" href="https://search-guard.com/contacts/" />
        <meta
          name="description"
          content="Any questions regarding Search Guard licensing or pricing? Do you need OEM licenses or want to partner with us? Please fill out the contact form and we will get back to you as soon as possible."
        />
      </Helmet>
      <Title
        headline="Get in touch"
        text="Any questions regarding Search Guard licensing or pricing? Do you need OEM licenses or want to partner with us? Please fill out the contact form and we will get back to you as soon as possible."
        breadcrumb={breadcrumb}
      />
      <ContactForm />
      <Cta
        headline="Explore the resource hub"
        text="From documentation and blog posts, to FAQs and more about our TLS certificate generator, take a look at our resource hub."
        ctaText="see ressource hub"
        icon={folderGlass}
        link="/resource/"
      />
      <PreFooter />
    </PageWrapper>
  );
};

export default ContactUs;
